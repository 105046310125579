import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserData from '../../../../Datas/UserData';
import refreshToken, {
  startRefreshContinuously,
} from '../../../../Methods/RefreshToken/RefreshToken';
import AdminContainer from './AdminContainer';
import CcloDatas from '../../../../Datas/ProjectDatas/ProjectDatas';
import AdminTitle from '../../../../Types/ProjectTypes/AdminTitle';
import AdminScreen from '../../../../Types/BeecomingTypes/AdminScreen';
import AdminScreenPathsList from '../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import OtherScreenPathsList from '../../../../Datas/BeecomingDatas/OtherScreenPathsList';
import AdminPageComponent from '../../../../Types/Interface/ComponentInterface/AdminPageComponent';
import SafeAdminScreenList from '../../../../Datas/BeecomingDatas/SafeAdminScreenList';

interface Props {
  title: AdminTitle;
  screenName: AdminScreen;
  menuPath: AdminScreen;
  adminPage: AdminPageComponent;
}

/**
 * TODO: edit this component so it does not save page which need url param as lastscreen
 * -> For instance EditWasteCollectException should not be the last screen
 * because if home page redirect to this screen (without a collect id nor a collectexception id)
 * the page will be useless
 */

/**
 * Component protecting admin pages as such:
 *
 * Before showing the page, send a request to the API to check if the user as correct acces token.
 * If the user access token is correct then he can acces the page.
 * Otherwise the user try to obtain a new access token with his refresh token.
 * If he is still unsuccesfull then he is redirect to the login page.
 * @param title title passed to {@link AdminContainer}
 * (not the page title but the top left button label)
 * @param screenName this is used to store the last screen in used in local storage
 * @param menuPath name of the screen we want to go back to when clicking the top left corner button
 * @param adminPage Admin page passed to {@link AdminContainer}
 * @returns A Component protecting admin pages from un loged user
 */

const AdminProtection: FunctionComponent<Props> = ({ title, screenName, menuPath, adminPage }) => {
  const history = useHistory();
  const [isLogedIn, setIsLogedIn] = useState(false);

  const onLogin = () => {
    setIsLogedIn(true);
    localStorage.setItem('last-screen', SafeAdminScreenList[screenName]);
  };

  useEffect(() => {
    let isSubscribed = true;

    const tryAccesToken = new Promise<void>((resolve, reject) => {
      const newToken: string | null = UserData.getUserProperty('token');
      if (newToken !== null) {
        fetch(`${CcloDatas.urlRequest}/utilisateur/est-admin`, {
          method: 'GET',
          headers: {
            authorization: `Bearer ${newToken}`,
          },
        }).then((response) => {
          if (response.ok) {
            resolve();
          } else {
            reject(response);
          }
        });
      } else {
        reject();
      }
    });
    tryAccesToken
      .then(() => {
        if (isSubscribed) {
          onLogin();
        }
      })
      .catch(() => {
        refreshToken().then((resp) => {
          if (isSubscribed) {
            if (resp == null) {
              setIsLogedIn(false);
              history.push(OtherScreenPathsList['admin-connection']);
            } else {
              UserData.setUser(resp);
              onLogin();
              startRefreshContinuously();
            }
          }
        });
      });
    return () => {
      isSubscribed = false;
    };
  }, []);
  return (
    <>
      {isLogedIn ? (
        <AdminContainer title={title} path={AdminScreenPathsList[menuPath]} adminPage={adminPage} />
      ) : null}
    </>
  );
};

export default AdminProtection;
