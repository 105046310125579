import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { FiberManualRecord, Visibility } from '@material-ui/icons';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import GarbageCollectorInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';
import SharedDataInterface from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import AdminScreenPathsList from '../../../../../Datas/BeecomingDatas/AdminScreenPathsList';

const ListItemWasteCollect: FunctionComponent<
  ListItemProps<GarbageCollectorInterface, SharedDataInterface>
> = ({ row, selectedItemsManager }) => {
  const [selected, setSelected] = useState<boolean>(selectedItemsManager.isSelected(row));
  const ccloClasses = UseBeecomingStyles();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const change = event.target.checked;
    setSelected(change);
    if (change) {
      selectedItemsManager.addItem(row);
    } else {
      selectedItemsManager.subItem(row);
    }
  };

  return (
    <TableRow className={selected ? 'selectedRow' : 'normalRow'}>
      <TableCell>
        <Checkbox color="primary" checked={selected} onChange={handleChange} size="small" />
        {row.zoneidlabel}
      </TableCell>
      <TableCell className="textWithIcon">
        <ListItem>
          <ListItemIcon>
            <FiberManualRecord
              className={row.typeid === 0 ? ccloClasses.waste_style : ccloClasses.packaging_style}
              fontSize="small"
            />
          </ListItemIcon>
          <ListItemText>{row.typeidlabel}</ListItemText>
        </ListItem>
      </TableCell>
      <TableCell align="center">{row.exceptions}</TableCell>
      <TableCell>{`${row.jour} (${row.semaineidlabel})`}</TableCell>
      <TableCell>
        <IconButton
          color="primary"
          onClick={() => {
            history.push(`${AdminScreenPathsList['waste-collect-edition']}?id=${row.collecteid}`);
          }}
        >
          <Visibility />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ListItemWasteCollect;
