/* eslint-disable react/jsx-one-expression-per-line */
import { Button, Link, TextField, ThemeProvider, Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import theme from '../../../Themes/ProjectTheme';
import UserData, { getUserFromApiOutput } from '../../../Datas/UserData';

import './AdminLogin.scss';
import CcloDatas from '../../../Datas/ProjectDatas/ProjectDatas';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';

interface Props {}
/**
 * @returns Admin Login Page
 */
const AdminLogin: FunctionComponent<Props> = () => {
  const history = useHistory();
  const [login, setLogin] = useState<string>(localStorage.getItem('email') ?? '');
  const [password, setPassword] = useState<string>('');
  const [errorLogin, setErrorLogin] = useState<boolean>(false);

  const tryLogin = () => {
    const requestData = {
      email: login,
      password,
    };
    // try to connect
    fetch(`${CcloDatas.urlRequest}/compte/connexion`, {
      method: 'PUT',
      /**
       * credetials is set to include so that Api can fiw user cookie
       * see https://developer.mozilla.org/fr/docs/Web/API/Request/credentials
       */
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      body: JSON.stringify(requestData),
    }).then((response) => {
      if (response.ok) {
        response.json().then((resp) => {
          UserData.setUser(getUserFromApiOutput(resp));
          localStorage.setItem('email', login);
          history.push(AdminScreenPathsList.home);
        });
      } else {
        setErrorLogin(true);
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="LoginScreenContainer" style={{ height: '100vh' }}>
        <div className="LoginContainer" style={{ maxWidth: '280pt', flexShrink: 0 }}>
          <div className="LoginHeader">
            <img
              style={{ width: '50%' }}
              src="https://api.cclo.beecoming.fr/ressources/icons/logo_cclo.jpg"
              alt=""
              className="cclo_logo"
            />
          </div>
          <div className="LoginBody">
            <div>
              <p className="LoginTitle">
                Connexion au Back-office de l&apos;application{' '}
                <span style={{ color: 'var(--main-color)' }}>Ma Cclo</span>
              </p>
            </div>

            <div>
              <div className="InputElement">
                <TextField
                  label="Identifiant"
                  value={login}
                  error={errorLogin}
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLogin(event.target.value);
                    setErrorLogin(false);
                  }}
                />
              </div>
              <div className="InputElement">
                <TextField
                  label="Mot de passe"
                  value={password}
                  error={errorLogin}
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="password"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                    setErrorLogin(false);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter') {
                      tryLogin();
                    }
                  }}
                />
              </div>
              <div className="InputElement">
                <Button variant="contained" onClick={tryLogin} fullWidth color="secondary">
                  Se connecter
                </Button>
              </div>
              <Typography>
                <Link component={RouterLink} to="/mot-de-passe/oublie">
                  Mot de passe oublié ?
                </Link>
              </Typography>
            </div>
          </div>
        </div>
        <div className="SplashScreenContainer" style={{ height: '100%' }} />
      </div>
    </ThemeProvider>
  );
};

export default AdminLogin;
