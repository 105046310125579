import DataInterface from './DataInterface';

interface WasteManagementInterface {
  recordid: string;
  fields: FieldProps;
  exceptions: string;
}

interface FieldProps {
  collecteid: string;
  typeid: string;
  typeidlabel: string;
  jour: string;
  zoneid: string;
  zoneidlabel: string;
  semaineid: string;
  semaineidlabel: string;
}

interface BasicGarbageCollector {
  collecteid: string;
  typeid: number;
  zoneid: number;
  zoneidlabel: string;
  semaineid: number;
}

interface GarbageCollectorInterface extends DataInterface, BasicGarbageCollector {
  typeidlabel: string;
  jour: string;
  semaineidlabel: string;
  exceptions: number;
  selected: boolean;
}

function FormatterBasicWaste(data: any): BasicGarbageCollector {
  return {
    collecteid: data.fields.collecteid,
    typeid: Number(data.fields.typeid),
    zoneid: Number(data.fields.zoneid),
    zoneidlabel: data.fields.zoneidlabel,
    semaineid: Number(data.fields.semaineid),
  };
}

function FormatterWaste(data: WasteManagementInterface): GarbageCollectorInterface {
  return {
    id: data.recordid,
    collecteid: data.fields.collecteid,
    typeid: Number(data.fields.typeid),
    typeidlabel: data.fields.typeidlabel,
    jour: data.fields.jour,
    zoneid: Number(data.fields.zoneid),
    zoneidlabel: data.fields.zoneidlabel,
    semaineid: Number(data.fields.semaineid),
    semaineidlabel: data.fields.semaineidlabel,
    exceptions: Number(data.exceptions),
    selected: false,
  };
}

export default GarbageCollectorInterface;
export type { BasicGarbageCollector };
export { FormatterWaste, FormatterBasicWaste };
