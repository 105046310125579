import React, { FunctionComponent } from 'react';
import NotificationAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/NotificationAdditionalArgs';
import { NotificationInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import SharedDataInterface from '../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import GetNotificationComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetNotificationComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderNotification from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderNotification';
import ListItemNotification from '../BeecomingCompenents/ListElements/ListItems/ListItemNotification';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import GetNotificationAdditionalQuery from '../../../Methods/TableQuery/GetAdditionalQueries/GetNotificationAdditionalQuery';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import { NotificationFormatter } from '../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';

interface Props {}

const getNotificationList = (additionalParamters: NotificationAdditionalArgs, history: any) => {
  const additionalQuery = GetNotificationAdditionalQuery(additionalParamters);
  const query = `/Notification/liste?${additionalQuery ? `&${additionalQuery}` : ''}`;
  return ApiFetch(query, 'GET', history);
};

const ListNotification: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<NotificationInterface, NotificationAdditionalArgs, SharedDataInterface>
    addNew="Nouvelle notification"
    defaultSharedData={{}}
    defaultAdditionalArgs={{ title: '', body: '', start: null, end: null }}
    defaultSort="title"
    defaultOrderType="asc"
    headComponent={ListHeaderNotification}
    listComponent={ListItemNotification}
    getList={{
      request: getNotificationList,
      formatter: NotificationFormatter,
      filter: (data: any) => data as NotificationInterface[],
      errorMessage: "La liste des notification ne s'est pas chargée normalement",
    }}
    getComparatorFromKey={GetNotificationComparatorFromKey}
    onError={onError}
  />
);

const ListNotificationProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Notifications"
    screenName="notification"
    menuPath="notification"
    adminPage={ListNotification}
  />
);

export default ListNotificationProtected;
