import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './AdminPages/HomePage';
import EmailConfirm from './OtherPages/EmailConfirm';
import PasswordReset from './OtherPages/PasswordReset';
import EmailChange from './OtherPages/EmailChange';
import PasswordChange from './OtherPages/PasswordChange';

import AdminLogin from './AdminPages/AdminLogin';
import AdminProfilProtected from './AdminPages/AdminProfilProtected';
import ListNotificationProtected from './AdminPages/ListNotificationProtected';
import ListActualityProtected from './AdminPages/ListActualityProtected';
import AdminEditNotificationProtected from './AdminPages/AdminEditNotificationProtected';
import AdminEditWasteCollectProtected from './AdminPages/AdminEditWasteCollectProtected';
import AdminEditExceptionProtected from './AdminPages/AdminEditExceptionProtected';
import AdminStatisticsProtected from './AdminPages/AdminStatisticsProtected';
import ListGarbageProtected from './AdminPages/ListGarbageProtected';
import ForgotPasswod from './OtherPages/ForgotPasswod';
import HomeRedirect from './HomeRedirect';
import CreateAccount from './OtherPages/CreateAccount';
import AdminPromoteProtected from './AdminPages/AdminPromoteProtected';
import OtherScreenPathsList from '../../Datas/BeecomingDatas/OtherScreenPathsList';
import AdminScreenPathsList from '../../Datas/BeecomingDatas/AdminScreenPathsList';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path={OtherScreenPathsList['email-confirm']}>
              <EmailConfirm />
            </Route>
            <Route path={OtherScreenPathsList['password-forgot']}>
              <ForgotPasswod />
            </Route>
            <Route path={OtherScreenPathsList['password-success']}>
              <PasswordChange />
            </Route>
            <Route path={OtherScreenPathsList['password-recup']}>
              <PasswordReset />
            </Route>
            <Route path={OtherScreenPathsList['email-change']}>
              <EmailChange />
            </Route>
            <Route path={OtherScreenPathsList['account-new']}>
              <CreateAccount />
            </Route>
            <Route path={AdminScreenPathsList.statistics}>
              <AdminStatisticsProtected />
            </Route>
            <Route path={AdminScreenPathsList.actuality}>
              <ListActualityProtected />
            </Route>
            <Route path={AdminScreenPathsList['notification-edition']}>
              <AdminEditNotificationProtected />
            </Route>
            <Route path={AdminScreenPathsList.notification}>
              <ListNotificationProtected />
            </Route>
            <Route path={AdminScreenPathsList['waste-collect-exception']}>
              <AdminEditExceptionProtected />
            </Route>
            <Route path={AdminScreenPathsList['waste-collect-edition']}>
              <AdminEditWasteCollectProtected />
            </Route>
            <Route path={AdminScreenPathsList['waste-collect']}>
              <ListGarbageProtected />
            </Route>
            <Route path={AdminScreenPathsList.account}>
              <AdminProfilProtected />
            </Route>
            <Route path={AdminScreenPathsList['admin-promote']}>
              <AdminPromoteProtected />
            </Route>
            <Route path={OtherScreenPathsList['admin-connection']}>
              <AdminLogin />
            </Route>
            <Route path={AdminScreenPathsList.home}>
              <HomeRedirect />
            </Route>
            <Route>
              <HomePage />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
