/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TableItemTheme from '../../../../Themes/TableItemTheme';
import DataInterface from '../../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import AdditionalArgsInterface from '../../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/AdditionalArgsInterface';
import SharedDataInterface from '../../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import AdminListContainerProps from '../../../../Types/Interface/Props/AdminContainerProps';
import SelectionManagerInterface from '../../../../Types/Interface/TableInterfaces/SelectionManagerInterface';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import { Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import BeecomingDatas from '../../../../Datas/BeecomingDatas/BeecomingDatas';

/**
 * Notes:
 * {@link sharedData} is primarly set to {@link defaultSharedData}
 * then if {@link getDefaultSharedData} is defined, we use it to reset {@link sharedData}
 * we could only use {@link getDefaultSharedData} but we want to initialize {@link sharedData}
 * with a valid {@link SharedData} but we don't which type it will be.
 *
 * Instead of passing directly values to {@link AdminListContainer}, we passed default value.
 * For instance:
 * - {@link defaultAdditionalArgs}
 * - {@link defaultSharedData}
 * - {@link defaultSort}
 *
 * This allow to use useState inside AdminListContainer and
 * avoid that {@link AdminListContainer} parent edit this value
 */

/**
 * This Method is used to show a table with the wanted data
 *
 * The genrics type are:
 * - {@link RowType}: item type, for instance: ActualityInterface
 * - {@link AdditionalArgs}: type of {@link additionalArgs}
 * a.k.a. additional parameters used for the {@link getList} method,
 * for instance: ActualityAdditionalArgs = {title, body, start, end}
 * - {@link SharedData}: type of {@link sharedData} a.k.a. the data shared (get & set) by all items,
 * for instance: ActualitySharedData = {highlight}
 *
 * To see prop types see {@link AdminListContainerProps}
 * @param addNew add button label (don't show the button if null)
 * @param defaultAdditionalArgs default {@link additionalArgs} used by {@link getList}
 * @param defaultSharedData default {@link sharedData}
 * @param defaultSort default {@link orderBy},
 * @param defaultOrderType default {@link orderType}
 * for instance: title (to sort items by their title (in alphabetic order))
 * @param headComponent Table head component
 * @param listComponent Table item component
 * @param linkOnSelected Allow the user to make an action after selecting items,
 * see {@link LinkOnSelectedInterface}
 * @param getList Method used to populate the table
 * @param getDefaultSharedData Method to get default {@link sharedData},
 * for instance: ask the API for hightlight element
 * @param getComparatorFromKey Method used to compare items
 * according to {@link orderBy} and {@link orderType}
 * (we need this method because key of {@link RowType} are different they need different comparator)
 * @param onError method handling the error
 * (AdminContainer uselly use a snackbar with a short explanation)
 * @returns Table React Component
 */

const AdminListContainer = <
  RowType extends DataInterface,
  AdditionalArgs extends AdditionalArgsInterface,
  SharedData extends SharedDataInterface,
>({
  addNew,
  defaultAdditionalArgs,
  defaultSharedData,
  defaultSort,
  headComponent,
  listComponent,
  linkOnSelected,
  getList,
  getDefaultSharedData = () => new Promise(() => null),
  getComparatorFromKey,
  onError,
  defaultOrderType,
}: AdminListContainerProps<RowType, AdditionalArgs, SharedData>): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [dataBlock, setDataBlock] = useState<RowType[]>([]);
  const [loader, setLoader] = useState<boolean>(true);

  // #region Table Header
  // #region states
  const [additionalArgs, setAdditionalArgs] = useState<AdditionalArgs>(defaultAdditionalArgs);
  const [orderType, setOrderType] = useState<Order>(defaultOrderType);
  const [orderBy, setOrderBy] = useState<keyof RowType>(defaultSort);
  // #endregion

  // #region Get final form response from Api response
  /**
   * Edit dataBlock according to args
   * @param args additiobal Args for the query
   * @param checkSubscription a method used to check if it is still possible to use useState method
   * @returns false if the array is empty, true otherwise
   */

  // in a useEffect, it is necessary to check if the component is still subscribe
  const setData = (
    args: AdditionalArgs,
    checkSubscription: () => boolean = () => true,
  ): Promise<boolean> =>
    getList.request(args, history).then((response) => {
      if (checkSubscription()) {
        if (response.ok) {
          return response
            .json()
            .then((result) => result.map((item: any) => getList.formatter(item)))
            .then((data) => {
              if (getList.filter !== undefined) {
                return getList.filter(data, args);
              }
              return data;
            })
            .then((rows: RowType[]) => {
              if (checkSubscription()) {
                setDataBlock(rows);
                setLoader(false);
                return rows.length > 0;
              }
              return false;
            });
        }
        onError(getList.errorMessage);
        return false;
      }
      return false;
    });
  // #endregion

  // #region Table Header functions
  /**
   * Set dataBlock according to args
   * This method is used by the table header component to edit the table.
   * Returning a promise enable the header component to know when the task have finished
   * @param args Additional Args
   * @returns true if the data set is not empty
   */
  const onChangeAdditionalArgs = (args: AdditionalArgs): Promise<boolean> => {
    setAdditionalArgs(args);
    return setData(args);
  };

  /**
   * Set either sorting in croissant or decroissant
   * @param ord OrderType: either 'asc' or 'desc'
   */
  const onChangeOrderType = (ord: Order) => {
    setOrderType(ord);
  };

  /**
   * Set the key used to sort items in the array
   * @param k item key used to sort the dataBlock array
   */
  const onChangeOrderBy = (k: keyof RowType) => {
    setOrderBy(k);
  };
  // #endregion
  // #endregion

  // #region Table Footstep
  const [page, setPage] = useState<number>(0);
  // const [totalRows, setTotalRows] = useState<number>(dataBlock.length);
  const [rowsPerPage, setRowsPerPage] = useState<number>(BeecomingDatas.globalDefaultRowsPerPage);

  /**
   * method called when the user is editing the number of rows per page
   * @param event event call when the user edit the number of row per page
   */
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * method call when the user is changing page
   * @param event event call when the user is changing page
   * @param newPage the new page
   */
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  // #endregion

  // #region Handle Selection
  const [numberSelected, setNumberSelected] = useState<number>(0);
  const [selectedList, setSelectedList] = useState<RowType[]>([]);

  /**
   * add a new element to the selection list
   * @param row new row to be added to selectedList
   */
  const addSelectedItem = (row: RowType) => {
    setNumberSelected(numberSelected + 1);
    setSelectedList(selectedList.concat(row));
  };

  /**
   * remove an element from the selection list
   * @param row row to be delete from selectedList
   */
  const subSelectedItem = (row: RowType) => {
    setNumberSelected(numberSelected - 1);
    const index = selectedList.indexOf(row);
    const newArray = selectedList;
    newArray.splice(index, 1);
    setSelectedList(newArray);
  };

  /**
   * tells if the row is in the selection list
   * @param row The row which may be selected
   * @returns either the row is selected
   */
  const isSelectedItem = (row: RowType): boolean => {
    const ro = selectedList.find((r: RowType) => r.id === row.id);
    return ro !== undefined;
  };

  /**
   * Selected Manager
   * addItem: add an item {@link addSelectedItem}
   * subItem: delete an item {@link subSelectedItem}
   * isSelected: check either an element is selected {@link isSelectedItem}
   */
  const selectedItemsManager: SelectionManagerInterface<RowType> = {
    addItem: addSelectedItem,
    subItem: subSelectedItem,
    isSelected: isSelectedItem,
  };
  // #endregion

  // #region Table Data
  const [sharedData, setSharedData] = useState<SharedData>(defaultSharedData);

  useEffect(() => {
    let isSubscribed = true;
    getDefaultSharedData(history).then((value) => {
      if (isSubscribed) {
        setSharedData(value);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, [getDefaultSharedData]);

  /**
   * Edit an existing row in dataBlock
   * @param row the row you want to edit
   */
  const setRow = (row: RowType) => {
    const newDataBlock = dataBlock;
    const index = newDataBlock.findIndex((r) => r.id === row.id);
    if (index !== -1) {
      newDataBlock.splice(index, 1, row);
    }
    setDataBlock(newDataBlock);
  };

  const getGetTableWithFilter =
    (comparator: GetComparatorFromKeyInterface<RowType>) => (): RowType[] => {
      if (comparator !== undefined && orderBy !== undefined) {
        return dataBlock.sort(comparator(orderBy, orderType));
      }
      return dataBlock;
    };

  const getTableData = (comparator: GetComparatorFromKeyInterface<RowType>): RowType[] =>
    getGetTableWithFilter(comparator)().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    setDataBlock([]);
    let isSubscribed = true;
    setLoader(true);
    const checkSubscription = () => isSubscribed;

    // setCount(setTotalRows, additionalArgs);
    setData(additionalArgs, checkSubscription);
    return () => {
      isSubscribed = false;
    };
  }, [additionalArgs]);

  // #endregion

  return (
    <div>
      <div className="adminSecondToolBar" style={{ display: 'flex' }}>
        {linkOnSelected !== undefined ? (
          <Button
            disabled={numberSelected <= 0}
            variant="contained"
            onClick={() => history.push(linkOnSelected.getPath(selectedList))}
            color="primary"
            style={{ marginRight: 'auto', marginLeft: '20pt' }}
          >
            {linkOnSelected.title}
          </Button>
        ) : null}

        {addNew !== undefined ? (
          <Button
            onClick={() => {
              history.push(`${location.pathname}/edition`);
            }}
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 'auto' }}
          >
            {addNew}
          </Button>
        ) : null}
      </div>
      <ThemeProvider theme={TableItemTheme}>
        <TableContainer>
          <Table>
            {headComponent({
              additionalArgs,
              onChangeAdditionalArgs,
              orderType,
              onChangeOrderType,
              orderBy,
              onChangeOrderBy,
            })}
            <TableBody>
              {getTableData(getComparatorFromKey).map((row: RowType) => {
                const MyItem = (): ReactElement | null =>
                  listComponent({
                    row,
                    sharedData,
                    setSharedData,
                    selectedItemsManager,
                    setRow,
                    onError,
                  });
                return <MyItem key={row.id} />;
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={getGetTableWithFilter(getComparatorFromKey)().length}
                  onPageChange={handleChangePage}
                  page={page}
                  labelRowsPerPage="Lignes par page"
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={BeecomingDatas.globalrowsPerPageOptions}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {loader && <CircularProgress style={{ position: 'absolute' }} />}
      </ThemeProvider>
    </div>
  );
};

export default AdminListContainer;
