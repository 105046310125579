import DataInterface from './DataInterface';

interface NotificationMessage {
  title: string;
  body: string;
  topics: NotificationTopic;
  imgUrl: string | null;
  Datas: {
    DatasetId: string;
    RecordId: string;
  };
}

type TopicCategory = 'Evénements' | 'Gestion des déchets';

interface NotificationTopic {
  category: TopicCategory;
  subCategories: string[];
  zones: string[];
}

interface NotificationFromFetch {
  notificationId?: string;
  notification: NotificationMessage;
  sendingTime: Date;
  interval: string;
  endInterval: Date;
}

interface NotificationInterface extends DataInterface {
  title: string;
  body: string;
  topics: NotificationTopic;
  imgUrl: string | null;
  sendingTime: Date;
  interval: string;
  endInterval: Date;
}

function NotificationFormatter(data: NotificationFromFetch): NotificationInterface {
  return {
    id: data.notificationId as string,
    title: data.notification.title,
    body: data.notification.body,
    topics: data.notification.topics,
    imgUrl: data.notification.imgUrl,
    sendingTime: data.sendingTime,
    interval: data.interval,
    endInterval: data.endInterval,
  };
}

export default NotificationInterface;
export { NotificationFormatter };
export type { NotificationFromFetch, NotificationTopic, TopicCategory };
