/* eslint-disable implicit-arrow-linebreak */
import BeecomingDatas from '../../Datas/BeecomingDatas/BeecomingDatas';
import CcloDatas from '../../Datas/ProjectDatas/ProjectDatas';

const refreshToken = (): Promise<any> =>
  fetch(`${CcloDatas.urlRequest}/compte/maintenir-la-connexion`, {
    method: 'POST',
    credentials: 'include',
  }).then((refreshResponse) => (refreshResponse.ok ? refreshResponse.json() : null));

const refreshContinuously = () => {
  refreshToken().then(() => {
    startRefreshContinuously();
  });
};

const startRefreshContinuously = () => {
  setTimeout(refreshContinuously, BeecomingDatas.tokenRefreshTime);
};

export default refreshToken;
export { startRefreshContinuously };
