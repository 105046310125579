import { createTheme } from '@material-ui/core/styles';
import ProjectColors from '../Datas/ProjectDatas/ProjectColors';

const ProjectTheme = createTheme({
  palette: {
    primary: {
      main: ProjectColors.primary,
    },
    secondary: {
      main: ProjectColors.secondary,
    },
  },
});

export default ProjectTheme;
