/* eslint-disable react/jsx-wrap-multilines */
import { Chip } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import useCcloStyles from '../../../Themes/UseBeecomingStyles';
import { BasicGarbageCollector } from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

interface Props {
  collect: BasicGarbageCollector;
}

const CollectChip: FunctionComponent<Props> = ({ collect }) => {
  const ccloClasses = useCcloStyles();
  return (
    <Chip
      style={{ margin: 2 }}
      icon={
        <FiberManualRecord
          className={collect.typeid === 0 ? ccloClasses.waste_style : ccloClasses.packaging_style}
          fontSize="small"
        />
      }
      label={collect.zoneidlabel}
      variant="outlined"
    />
  );
};

export default CollectChip;
