import React, { FunctionComponent } from 'react';
import GarbageCollecAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/GarbageCollectAdditionalArgs';
import { GarbageCollectorInterface } from '../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';
import SharedDataInterface from '../../../Types/Interface/TableInterfaces/SharedDataInterface/SharedDataInterface';
import GarbageCollectExcetionLink from '../../../Datas/ExceptionLinks/GarbageCollectExcetionLink';
import GetWasteComparatorFromKey from '../../../Methods/TableQuery/HandleFilter/HandleSort/GetWasteComparatorFromKey';
import AdminListContainer from '../BeecomingCompenents/AdminContainers/AdminListContainer';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import ListHeaderGarbageCollect from '../BeecomingCompenents/ListElements/ListHeaders/ListHeaderGarbageCollect';
import ListItemWasteCollect from '../BeecomingCompenents/ListElements/ListItems/ListItemWasteCollect';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import GetWasteAdditionalQuery, {
  parseWasteList,
} from '../../../Methods/TableQuery/GetAdditionalQueries/GetWasteAdditionalQuery';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import { FormatterWaste } from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

interface Props {}

const getGarbageCollectList = (additionalParameters: GarbageCollecAdditionalArgs, history: any) => {
  const queryParameter = GetWasteAdditionalQuery(additionalParameters);
  const query = queryParameter != null ? `&additionalParameters=${queryParameter}` : '';
  const path = `/OpenData/collecte_dechets?showUpcoming=false&fields=collecteid,typeid,typeidlabel,jour,zoneid,zoneidlabel,semaineid,semaineidlabel${query}`;
  return ApiFetch(path, 'GET', history);
};

const ListGarbage: AdminPageComponent = ({ onError }) => (
  <AdminListContainer<GarbageCollectorInterface, GarbageCollecAdditionalArgs, SharedDataInterface>
    defaultAdditionalArgs={{ title: '', garbageType: -1, days: [], weeks: [] }}
    defaultSharedData={{}}
    defaultSort="zoneidlabel"
    defaultOrderType="asc"
    headComponent={ListHeaderGarbageCollect}
    listComponent={ListItemWasteCollect}
    linkOnSelected={GarbageCollectExcetionLink}
    getList={{
      request: getGarbageCollectList,
      formatter: FormatterWaste,
      filter: parseWasteList,
      errorMessage: "La liste des collecte des déchets ne s'est pas chargée correctement.",
    }}
    getComparatorFromKey={GetWasteComparatorFromKey}
    onError={onError}
  />
);

const ListGarbageProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des déchets"
    screenName="waste-collect"
    menuPath="waste-collect"
    adminPage={ListGarbage}
  />
);

export default ListGarbageProtected;
