import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useCcloStyles from '../../../Themes/UseBeecomingStyles';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditPasswordDialog: FunctionComponent<Props> = ({ open, setOpen }) => {
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [error, setError] = useState<string>('');

  const ccloClasses = useCcloStyles();

  const handleCloseChangePassword = () => {
    setOpen(false);
  };
  function handleError401() {
    setError('Erreur dans le mot de passe actuel');
  }
  function handleError400() {
    setError('Erreur dans la confirmation du mot de passe');
  }
  const handleChangePassword = () => {
    const requestData = {
      currentPassword,
      newPassword,
      passwordConfirmation,
    };
    ApiFetch('/utilisateur/mes-informations/mot-de-passe', 'PUT', history, requestData).then(
      (resp) => {
        if (resp.ok) {
          handleCloseChangePassword();
        } else if (resp.status === 401) {
          handleError401();
        } else if (resp.status === 400) {
          handleError400();
        }
      },
    );
  };

  return (
    <Dialog open={open} onClose={handleCloseChangePassword}>
      <DialogContent>
        <div>
          <TextField
            style={{ padding: 10 }}
            value={currentPassword}
            label="Mot de passe courant"
            type="password"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentPassword(event.target.value);
              setError('');
            }}
          />
        </div>
        <div>
          <TextField
            style={{ padding: 10 }}
            value={newPassword}
            label="Nouveau mot de passe"
            type="password"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewPassword(event.target.value);
              setError('');
            }}
          />
        </div>
        <div>
          <TextField
            style={{ padding: 10 }}
            value={passwordConfirmation}
            label="Confirmation du mot de passe"
            type="password"
            variant="outlined"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordConfirmation(event.target.value);
              setError('');
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Enter') {
                handleChangePassword();
              }
            }}
          />
        </div>
        <div>
          <text style={{ color: 'red', alignContent: 'center' }}>{error}</text>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className={ccloClasses.BeecomingButton} onClick={handleCloseChangePassword}>
          Annuler
        </Button>
        <Button className={ccloClasses.BeecomingButton} onClick={handleChangePassword}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPasswordDialog;
