import AdminScreen from '../../Types/BeecomingTypes/AdminScreen';

type SafeAdminScreen = {
  [_screen in AdminScreen]: AdminScreen;
};

/**
 * Some screen cannot be saved as last screen in the local storage
 * (so that the user return to them in the next session)
 * because they are dependant of a record which is not saved in local storage.
 * So those screen will not render properly.
 *
 * For each admin screen, this object gives a safe admin screen
 * (a screen )
 *
 * see {@link AdminScreen}
 */
const SafeAdminScreenList: SafeAdminScreen = {
  statistics: 'statistics',
  actuality: 'actuality',
  notification: 'notification',
  'notification-edition': 'notification',
  'waste-collect': 'waste-collect',
  'waste-collect-edition': 'waste-collect',
  'waste-collect-exception': 'waste-collect',
  account: 'account',
  'admin-promote': 'admin-promote',
  home: 'home',
};

export default SafeAdminScreenList;
