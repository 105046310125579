import NavigationItem from '../../Types/BeecomingTypes/NavigationItem';

const NavigationList: NavigationItem[] = [
  {
    label: 'Statistiques',
    imgSrc: 'https://api.cclo.beecoming.fr/ressources/icons/parameter.png',
    node: 'statistics',
  },
  {
    label: 'Administration',
    imgSrc: 'https://api.cclo.beecoming.fr/ressources/icons/admin-parameter.png',
    node: [
      {
        label: 'Notifications',
        to: 'notification',
      },
      {
        label: 'Actualités',
        to: 'actuality',
      },
      {
        label: 'Gestion des déchets',
        to: 'waste-collect',
      },
      {
        label: 'Promouvoir un utilisateur',
        to: 'admin-promote',
      },
    ],
  },
];

export default NavigationList;
