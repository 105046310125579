/* eslint-disable implicit-arrow-linebreak */
import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import GarbageCollectAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/GarbageCollectAdditionalArgs';
import GarbageCollectorInterface from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';
import StringContains from '../../StringContains';

const GetWasteAdditionalQuery: GetAdditionalQueryInterface<GarbageCollectAdditionalArgs> = (
  additionalQuery,
) => {
  const { garbageType, days, weeks } = additionalQuery;
  const otherParameters: string[] = [];

  if (garbageType !== -1) {
    otherParameters.push(`typeid=eq.${String(garbageType)}`);
  }
  if (days.length > 1) {
    let dayQuery = `or=(jour.eq.${days[0]}`;
    for (let i = 1, l = days.length; i < l; i += 1) {
      dayQuery += `,jour.eq.${days[i]}`;
    }
    dayQuery += ')';
    otherParameters.push(dayQuery);
  } else if (days.length !== 0) otherParameters.push(`jour=eq.${String(days[0])}`);
  if (weeks.length > 1) {
    let weekQuery = `or=(semaineid.eq.${weeks[0]}`;
    for (let i = 1, l = weeks.length; i < l; i += 1) {
      weekQuery += `,semaineid.eq.${weeks[i]}`;
    }
    weekQuery += ')';
    otherParameters.push(weekQuery);
  } else if (weeks.length !== 0) otherParameters.push(`semaineid=eq.${String(weeks[0])}`);
  if (otherParameters.length === 0) {
    return null;
  }
  let res: string = otherParameters[0];
  for (let i = 1, l = otherParameters.length; i < l; i += 1) {
    res += `&${otherParameters[i]}`;
  }
  return res;
};

const parseWasteList = (
  dataBlock: GarbageCollectorInterface[],
  args: GarbageCollectAdditionalArgs,
): GarbageCollectorInterface[] =>
  dataBlock.filter((row) => StringContains(row.zoneidlabel, args.title));

export default GetWasteAdditionalQuery;
export { parseWasteList };
