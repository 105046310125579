import DisplayLevel from '../../../BeecomingTypes/DisplayLevel';
import DataInterface from './DataInterface';

interface ActualityFromFetch {
  recordid: string;
  fields: FieldsInterface;
  level: DisplayLevel;
}

interface FieldsInterface {
  title: string;
  published: Date;
}

interface ActualityInterface extends DataInterface {
  title: string;
  level: DisplayLevel;
  published: Date;
}

function ActualityFormatter(data: ActualityFromFetch): ActualityInterface {
  return {
    id: data.recordid,
    title: data.fields.title,
    level: data.level ?? -1,
    published: data.fields.published,
  };
}

export default ActualityInterface;
export { ActualityFormatter };
