import GetAdditionalQueryInterface from '../../../Types/Interface/MethodInterface/GetAdditionalQueryInteface';
import NotificationAdditionalArgs from '../../../Types/Interface/TableInterfaces/AdditionalArgsInterface/NotificationAdditionalArgs';

const GetNotificationAdditionalQuery: GetAdditionalQueryInterface<NotificationAdditionalArgs> = (
  additionalQuery: NotificationAdditionalArgs,
) => {
  const { title, body, start, end } = additionalQuery;
  const otherParameters: string[] = [];

  if (title !== '') {
    otherParameters.push(`titre=${title}`);
  }
  if (body !== '') {
    otherParameters.push(`description=${body}`);
  }
  if (start != null) {
    otherParameters.push(`debut=${start}`);
  }
  if (end != null) {
    otherParameters.push(`end=${end}`);
  }
  if (otherParameters.length === 0) {
    return null;
  }
  let res = otherParameters[0];
  for (let i = 1, l = otherParameters.length; i < l; i += 1) {
    res += `&${otherParameters[i]}`;
  }
  return res;
};

export default GetNotificationAdditionalQuery;
