import assert from 'assert';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GarbageCollectorInterface, {
  FormatterWaste,
} from '../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

import '../Admin.scss';
import '../BeecomingCompenents/AdminDataDisplayer.scss';
import AdminDisplayExceptions from '../BeecomingCompenents/AdminDisplayExceptions';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import DisplayProperty from '../BeecomingCompenents/DisplayProperty';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Garbage Collect Edition and Creation Page
 */

const AdminEditWasteCollect: AdminPageComponent = ({ onError }) => {
  const history = useHistory();
  const [collectId, setCollectId] = useState<string | null>(null);
  const [collectZone, setCollectZone] = useState<string>('');
  const [collectType, setCollectType] = useState<string>('');
  const [day, setDay] = useState<string>('');
  const [collectWeekType, setCollectWeekType] = useState<string>('');

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get('id');
    if (id !== null) {
      setCollectId(id);
      ApiFetch(
        `/OpenData/collecte_dechets?rows=1&showUpcoming=false&additionalParameters=collecteid=eq.${id}`,
        'GET',
        history,
      ).then((response) => {
        if (response.ok) {
          response
            .json()
            .then((r) => FormatterWaste(r[0]))
            .then((resp: GarbageCollectorInterface) => {
              assert(id === resp.collecteid);
              setCollectZone(resp.zoneidlabel);
              setCollectType(resp.typeidlabel);
              setDay(resp.jour);
              setCollectWeekType(resp.semaineidlabel);
            });
        } else {
          onError('Impossible de récupérer les informations de la collecte.');
        }
      });
    } else {
      onError("Une erreur s'est produite, impossible de charger la collecte.");
    }
  }, []);

  return (
    <div>
      <h1>Détails du parcours</h1>
      <div className="newElementContainer">
        <div className="newElementColumn">
          <DisplayProperty label="Identifiant de la collecte :" value={collectId ?? ''} />
          <DisplayProperty label="Jour de la collecte :" value={day} />
          <DisplayProperty label="Semaine :" value={collectWeekType} />
        </div>
        <div className="newElementColumn">
          <DisplayProperty label="Zone de la collecte :" value={collectZone} />
          <DisplayProperty label="Type de la collecte :" value={collectType} />
        </div>
      </div>
      <div>
        <p>Exception (jour de passage)</p>
        <AdminDisplayExceptions collectId={collectId} onError={onError} />
      </div>
    </div>
  );
};

const AdminEditWasteCollectProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Gestion des déchets"
    screenName="waste-collect-edition"
    menuPath="waste-collect"
    adminPage={AdminEditWasteCollect}
  />
);

export default AdminEditWasteCollectProtected;
