/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Modal,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { grey, yellow } from '@material-ui/core/colors';
import { Star, StarBorder } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../../../Themes/UseBeecomingStyles';
import ActualityInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/ActualityInterface';
import ListItemProps from '../../../../../Types/Interface/Props/ListItemProps';
import ActualitySharedData from '../../../../../Types/Interface/TableInterfaces/SharedDataInterface/ActualitySharedData';
import HandleCheckBoxActuality from '../../../../../Methods/ActualityHandler/HandleCheckBoxActuality';
import HandleCheckBoxMainActuality from '../../../../../Methods/ActualityHandler/HandleCheckBoxMainActuality';
import DisplayLevel from '../../../../../Types/BeecomingTypes/DisplayLevel';

const ListItemActuality: FunctionComponent<ListItemProps<ActualityInterface, ActualitySharedData>> =
  ({ row, sharedData, setSharedData, setRow, onError }) => {
    const history = useHistory();
    const [childRow, setChildRow] = useState<ActualityInterface>(row);
    const [shown, setShown] = useState<boolean>(row.level >= DisplayLevel.Listed);
    const [inCarousel, setInCarousel] = useState<boolean>(
      row.level === DisplayLevel.Carousel || row.level === DisplayLevel.CarouselAndHighlight,
    );
    const [open, setOpen] = useState<boolean>(false);

    // loaders
    const [shownLoader, setShownLoader] = useState<boolean>(false);
    const [carousselLoader, setCarousselLoader] = useState<boolean>(false);
    const [hightlightLoader, setHightlightLoader] = useState<boolean>(false);
    const [star, setStar] = useState<boolean>(row.id === sharedData.highlight);
    const [currentBox, setCurrentBox] = useState<'carousel' | 'star' | 'shown'>('shown');

    // eslint-disable-next-line prefer-destructuring
    const highlight = sharedData.highlight;
    const highlightSetter = (h: string) => {
      setSharedData({ highlight: h });
    };
    const setDisplayLevel = (l: DisplayLevel): void => {
      const newRow: ActualityInterface = childRow;
      newRow.level = l;
      // need to update data in parent and child
      setRow(newRow);
      setChildRow(newRow);
      setShown(newRow.level >= DisplayLevel.Listed);
      setInCarousel(
        newRow.level === DisplayLevel.Carousel ||
          newRow.level === DisplayLevel.CarouselAndHighlight,
      );
    };

    const YellowCheckBox = withStyles({
      root: {
        color: grey[500],
        '&$checked': {
          color: yellow[600],
        },
      },
      checked: {},
      // eslint-disable-next-line react/jsx-props-no-spreading
    })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
    const classes = UseBeecomingStyles();

    const handleClose = () => {
      setOpen(false);
    };

    const handleError = () => {
      onError("Une erreur s'est produite");
    };

    return (
      <TableRow>
        <TableCell align="left">
          {hightlightLoader && <CircularProgress className={classes.loader} />}
          <YellowCheckBox
            color="default"
            // checked={row.id === sharedData.highlight}
            checked={row.level === 3}
            icon={<StarBorder />}
            checkedIcon={<Star />}
            onChange={async (event) => {
              setCurrentBox('star');

              if (!shown) setOpen(true);
              else {
                HandleCheckBoxActuality(
                  row.id,
                  false,
                  highlight,
                  setDisplayLevel,
                  highlightSetter,
                  setHightlightLoader,
                  handleError,
                  history,
                  row.id === sharedData.highlight,
                  inCarousel,
                  shown,
                  'star',
                );
              }
            }}
          />
        </TableCell>
        <TableCell align="left">{row.title}</TableCell>
        <TableCell align="center">{new Date(row.published).toLocaleDateString()}</TableCell>
        <TableCell align="center">
          {shownLoader && <CircularProgress className={classes.loader} />}
          <Checkbox
            checked={shown}
            onChange={async (event) => {
              setCurrentBox('shown');
              if (!shown) setOpen(true);
              else {
                HandleCheckBoxActuality(
                  row.id,
                  false,
                  highlight,
                  setDisplayLevel,
                  highlightSetter,
                  setShownLoader,
                  handleError,
                  history,
                  row.id === sharedData.highlight,
                  inCarousel,
                  shown,
                  'shown',
                );
              }
            }}
          />
        </TableCell>
        <TableCell align="center">
          {carousselLoader && <CircularProgress className={classes.loader} />}
          <Checkbox
            checked={inCarousel}
            onChange={async (event) => {
              setCurrentBox('carousel');
              if (!shown) {
                setOpen(true);
              } else {
                HandleCheckBoxActuality(
                  row.id,
                  false,
                  highlight,
                  setDisplayLevel,
                  highlightSetter,
                  setCarousselLoader,
                  handleError,
                  history,
                  row.id === sharedData.highlight,
                  inCarousel,
                  shown,
                  'carousel',
                );
              }
            }}
          />
        </TableCell>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          style={{
            position: 'absolute' as 'absolute',
            left: '38%',
            top: '38%',
            width: 400,
            height: 200,
            alignItems: 'center',
            outline: '0',
            justifyContent: 'center',
            borderRadius: '30px',
          }}
        >
          <Box
            m={1}
            className={`${classes.spreadBox} ${classes.box}`}
            style={{ borderRadius: '10px', borderWidth: '2px' }}
          >
            <h2
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#e30613',
              }}
            >
              Envoi de notification
            </h2>
            <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Voulez-vous envoyer une notification ?
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: '10px',
              }}
            >
              <Button
                variant="outlined"
                onClick={async (event) => {
                  HandleCheckBoxActuality(
                    row.id,
                    true,
                    highlight,
                    setDisplayLevel,
                    highlightSetter,
                    currentBox === 'shown'
                      ? setShownLoader
                      : currentBox === 'star'
                      ? setHightlightLoader
                      : setCarousselLoader,
                    handleError,
                    history,
                    row.id === sharedData.highlight,
                    inCarousel,
                    shown,
                    currentBox,
                  );
                  handleClose();
                }}
              >
                Oui
              </Button>
              <Button
                variant="outlined"
                onClick={async (event) => {
                  HandleCheckBoxActuality(
                    row.id,
                    false,
                    highlight,
                    setDisplayLevel,
                    highlightSetter,
                    currentBox === 'shown'
                      ? setShownLoader
                      : currentBox === 'star'
                      ? setHightlightLoader
                      : setCarousselLoader,
                    handleError,
                    history,
                    row.id === sharedData.highlight,
                    inCarousel,
                    shown,
                    currentBox,
                  );
                  handleClose();
                }}
              >
                Non
              </Button>
            </div>
          </Box>
        </Modal>
      </TableRow>
    );
  };

export default ListItemActuality;
