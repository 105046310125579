/* eslint-disable wrap-iife */
/* eslint-disable operator-linebreak */
import 'date-fns';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';

import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

import '../Admin.scss';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { useHistory } from 'react-router-dom';
import NotificationInterface, {
  NotificationFormatter,
  NotificationFromFetch,
  NotificationTopic,
  TopicCategory,
} from '../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';
import TopicSelector from '../BeecomingCompenents/TopicSelector';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';
import projectDatas from '../../../Datas/ProjectDatas/ProjectDatas';

interface Props {}

/**
 * @param onError method: show a snackbar with the chosen message
 * @returns Notification Edit and Creation Page
 */

const AdminEditNotification: AdminPageComponent = ({ onError }) => {
  const history = useHistory();

  const [pageTitle, setPageTitle] = useState<string>('Ajout de notification');

  const [errorTitle, setErrorTitle] = useState<boolean>(false);
  const [errorBody, setErrorBody] = useState<boolean>(false);
  const [errorTopics, setErrorTopics] = useState<boolean>(false);

  const [id, setId] = useState<string | null>();
  const [title, setTitle] = useState<string | null>('');
  const [body, setBody] = useState<string | null>('');
  const [imgUrl, setImgUrl] = useState<string | null>('');
  const [topics, setTopics] = useState<NotificationTopic>({
    category: 'Evénements',
    subCategories: [],
    zones: [],
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [interval, setInterval] = useState<string>('00:00:00');
  const [endIntervalDate, setEndIntervalDate] = useState<Date | null>(new Date());

  const handleDateChange = (date: Date | null) => {
    date?.setSeconds(0);
    date?.setMilliseconds(0);
    setSelectedDate(date);
  };

  const handleEndIntervalDateChange = (date: Date | null) => {
    date?.setSeconds(0);
    date?.setMilliseconds(0);
    setEndIntervalDate(date);
  };

  // #region handle Validation
  /**
   * @returns either the fields are valids
   */
  const isValid = (): boolean => {
    let valid: boolean = true;
    if (title === '') {
      valid = false;
      setErrorTitle(true);
    }
    if (body === '') {
      valid = false;
      setErrorBody(true);
    }
    if (topics.subCategories.length === 0 || topics.zones.length === 0) {
      valid = false;
      setErrorTopics(true);
    }
    if (selectedDate === null) {
      valid = false;
    }
    return valid;
  };
  // #endregion
  /**
   * Ask Api to edit or create a notfication
   */
  const handleValidation = async () => {
    if (isValid()) {
      const requestData: NotificationFromFetch = {
        notificationId: id ?? undefined,
        notification: {
          title: title as string,
          body: body as string,
          imgUrl: imgUrl !== '' ? imgUrl : null,
          topics,
          Datas: {
            DatasetId: 'cclo',
            RecordId: Math.random()
              .toString(36)
              .replace(/[^a-z]+/g, '')
              .substr(0, 5),
          },
        },
        sendingTime: selectedDate as Date,
        interval,
        endInterval: endIntervalDate as Date,
      };
      ApiFetch('/Notification/enregister-une-notification', 'POST', history, requestData).then(
        (response) => {
          if (response.ok) {
            history.push(AdminScreenPathsList.notification);
          } else {
            onError("La notification n'a pas été envoyée");
          }
        },
      );
    }
  };
  // #endregion

  /**
   * Ask API to delete a notification
   */
  const handleDelete = () => {
    ApiFetch(
      `/Notification/supprimer-une-notification?notificationId=${id}`,
      'DELETE',
      history,
    ).then((response) => {
      if (response.ok) {
        history.push(AdminScreenPathsList.notification);
      }
    });
  };

  const devValue = () => {
    if (
      projectDatas.urlRequest === 'https://api.cclo-dev.beecoming.fr' ||
      projectDatas.urlRequest === 'https://localhost:5001'
    ) {
      return <MenuItem value="00:00:10">Toutes les 10 secondes (dev only)</MenuItem>;
    }
    return null;
  };
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const notifId = params.get('id');
    setId(notifId);
    // if notifId is null then the user is creating a new notification
    if (notifId === null) {
      // look for url parameter to set notification parameter
      // (used for creating new notification)
      setTitle(params.get('titre') ?? '');
      setBody(params.get('corps') ?? '');
      setImgUrl(params.get('imgUrl') ?? '');
      const topicCategory: TopicCategory =
        params.get('topicCategorie') === 'Gestion des déchets'
          ? 'Gestion des déchets'
          : 'Evénements';
      const topicSubCategories: string[] = params.getAll('topicSousCategories');
      const topicZones: string[] = params.getAll('topicZones');
      setTopics({
        category: topicCategory,
        subCategories: topicSubCategories,
        zones: topicZones,
      });
      setSelectedDate(new Date(params.get('date') ?? new Date()));
      setInterval(params.get('interval') ?? '00:00:00');
    } else {
      // ask api to set notification parameter
      // (used for editing existing notification)
      setPageTitle('Edition de notification');
      ApiFetch(`/Notification/notification?notificationId=${notifId}`, 'GET', history)
        .then((response) => response.json())
        .then((r) => NotificationFormatter(r))
        .then((resp: NotificationInterface) => {
          setTitle(resp.title);
          setBody(resp.body);
          setTopics(resp.topics);
          setSelectedDate(resp.sendingTime);
          setInterval(resp.interval);
          setEndIntervalDate(resp.endInterval);
        });
    }
  }, []);

  return (
    <div>
      <h1>{pageTitle}</h1>
      <div className="newElementContainer">
        <div className="newElementColumn">
          <div className="newElementProperty">
            <TextField
              label="Titre de la notification"
              value={title}
              error={errorTitle}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
                setErrorTitle(false);
              }}
            />
          </div>
          <div className="newElementProperty">
            <TextField
              label="Texte de la notification"
              value={body}
              error={errorBody}
              required
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setBody(event.target.value);
                setErrorBody(false);
              }}
            />
          </div>

          <div className="newElementProperty">
            <TextField
              value={imgUrl}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label="URL de l'image"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setImgUrl(event.target.value);
              }}
            />
          </div>
          <div className="newElementProperty">
            <Button fullWidth variant="contained" color="primary" onClick={handleValidation}>
              Valider
            </Button>
            {id !== null ? (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                style={{ marginTop: 20 }}
              >
                Supprimer
              </Button>
            ) : null}
          </div>
        </div>
        <div className="newElementColumn">
          <div className="newElementProperty">
            <TopicSelector
              topics={topics}
              setTopics={setTopics}
              error={errorTopics}
              setError={setErrorTopics}
            />
          </div>
          <p>Planifier l&apos;envoi</p>
          <div style={{ display: 'flex', flexDirection: 'row' }} className="newElementProperty">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <KeyboardDatePicker
                required
                style={{ marginRight: '10pt' }}
                value={selectedDate}
                onChange={handleDateChange}
                format="dd/MM/yyyy"
                okLabel="OK"
                cancelLabel="Annuler"
                invalidDateMessage="Date invalide"
              />
              <KeyboardTimePicker
                required
                style={{ marginLeft: '10pt' }}
                value={selectedDate}
                ampm={false}
                onChange={handleDateChange}
                okLabel="OK"
                cancelLabel="Annuler"
                invalidDateMessage="Horaire invalide"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="newElementProperty">
            <FormControl fullWidth>
              <InputLabel>Répétition</InputLabel>
              <Select
                onChange={(event: ChangeEvent<{ value: unknown }>) => {
                  setInterval(String(event.target.value));
                }}
                defaultValue="00:00:00"
                value={interval}
              >
                <MenuItem value="00:00:00">Jamais</MenuItem>
                {devValue()}
                <MenuItem value="1.0:00:00">Tous les jours</MenuItem>
                <MenuItem value="7.0:00:00">Toutes les semaines</MenuItem>
                <MenuItem value="14.0:00:00">Toutes les deux semaines</MenuItem>
              </Select>
            </FormControl>
          </div>
          {(function () {
            if (interval !== '00:00:00') {
              return (
                <div>
                  <p>Fin de la répétition</p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <KeyboardDatePicker
                      required
                      style={{ marginRight: '10pt' }}
                      value={endIntervalDate}
                      onChange={handleEndIntervalDateChange}
                      format="dd/MM/yyyy"
                      okLabel="OK"
                      cancelLabel="Annuler"
                      invalidDateMessage="Date invalide"
                    />
                    <KeyboardTimePicker
                      required
                      style={{ marginLeft: '10pt' }}
                      value={endIntervalDate}
                      ampm={false}
                      onChange={handleEndIntervalDateChange}
                      okLabel="OK"
                      cancelLabel="Annuler"
                      invalidDateMessage="Horaire invalide"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              );
            }
            return null;
          })()}
        </div>
      </div>
    </div>
  );
};

const AdminEditNotificationProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Notifications"
    screenName="notification-edition"
    menuPath="notification"
    adminPage={AdminEditNotification}
  />
);

export default AdminEditNotificationProtected;
