import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TableItemTheme from '../../../Themes/TableItemTheme';
import '../Admin.scss';
import useCcloStyles from '../../../Themes/UseBeecomingStyles';
import CollectExceptionInterface from '../../../Types/Interface/TableInterfaces/DataInterface/CollectExceptionInterface';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import OnErrorInterface from '../../../Types/Interface/MethodInterface/OnErrorInterface';

interface Props {
  collectId: string | null;
  onError: OnErrorInterface;
}

const AdminDisplayExceptions: FunctionComponent<Props> = ({ collectId, onError }) => {
  const history = useHistory();
  const [exceptionLists, setExceptionLists] = useState([]);

  useEffect(() => {
    if (collectId !== null) {
      ApiFetch(`/Exception-Collecte-dechets/list?collecteId=${collectId}`, 'GET', history).then(
        (response) => {
          if (response.ok) {
            response.json().then((resp) => {
              setExceptionLists(resp);
            });
          } else {
            onError("Les exceptions de la collecte n'ont pas pu être affichées.");
          }
        },
      );
    }
  }, [collectId]);

  const ccloClasses = useCcloStyles();

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          history.push(
            `${AdminScreenPathsList['waste-collect-exception']}?collecteId=${collectId}`,
          );
        }}
      >
        Ajouer une exception
      </Button>
      <ThemeProvider theme={TableItemTheme}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Exception</TableCell>
                <TableCell>Jour de remplacement</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {exceptionLists.map((row: CollectExceptionInterface) => (
                <TableRow>
                  <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
                  <TableCell>
                    {row.newDate
                      ? new Date(row.newDate).toLocaleDateString()
                      : 'pas de remplacement'}
                  </TableCell>
                  <TableCell>
                    <Button
                      className={ccloClasses.BeecomingButton}
                      onClick={() => {
                        history.push(
                          `${AdminScreenPathsList['waste-collect-exception']}?id=${row.collectExceptionId}`,
                        );
                      }}
                    >
                      Editer
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default AdminDisplayExceptions;
