/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Chart from 'react-google-charts';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import UseBeecomingStyles from '../../../Themes/UseBeecomingStyles';
import TranslateScreen from '../../../Methods/TranslateScreen';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

import '../Admin.scss';
import './Statistics.scss';

interface Props {}

interface DataNumber {
  label: string;
  details: string;
  subLabel?: string;
  path: string;
  value: number | null;
  valueComplement?: () => string;
  setter: React.Dispatch<React.SetStateAction<number | null>>;
}

interface ChartsData {
  labels: string[][];
  path: string;
  values: any[];
  setter: React.Dispatch<React.SetStateAction<any[]>>;
  loader: boolean;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  translate: (_s: string) => string;
}
interface ChartDisplayProps {
  title: string;
  data: ChartsData;
}

const getYesterdayString = (): Date => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

const getDataNumberValue = (n: number | null): string => {
  switch (n) {
    case null:
      return 'chargement...';
    case -1:
      return 'erreur';
    default:
      return n.toString();
  }
};

const DataNumberDisplay: FunctionComponent<{ data: DataNumber }> = ({ data }) => (
  <Tooltip title={data.details} placement="bottom-end">
    <Card className="Card">
      <CardHeader
        title={<p className="CardTitle">{data.label}</p>}
        subheader={
          data.subLabel !== undefined ? <p className="CardSubTitle">{data.subLabel}</p> : undefined
        }
        disableTypography
        style={{ padding: '0' }}
      />
      <CardContent style={{ padding: '0' }}>
        <p className="CardBody">
          {getDataNumberValue(data.value)}{' '}
          {data.valueComplement !== undefined ? data.valueComplement() : null}
        </p>
      </CardContent>
    </Card>
  </Tooltip>
);

const ChartDataDisplay: FunctionComponent<ChartDisplayProps> = ({ title, data }) => (
  <div className="newElementProperty">
    <div style={{ height: 300, overflowY: 'scroll' }}>
      <Chart
        height={200 + data.values.length * 20}
        chartType="Bar"
        loader={<p>Chargement du graph</p>}
        data={data.labels.concat(data.values)}
        options={{
          chart: {
            title,
          },
          bars: 'horizontal',
          legend: { position: 'none' },
          axes: {
            x: {
              0: { side: 'button', label: data.labels[0][1] },
            },
          },
        }}
      />
    </div>
  </div>
);

const AdminStatistics: AdminPageComponent = ({ onError }) => {
  const history = useHistory();
  const classes = UseBeecomingStyles();

  // #region data shown with charts
  const screenViewLabel = [['écran', 'nombre de vues']];
  const [screenViewData, setScreenViewData] = useState<any[]>([]);
  const [isScreenViewLoaded, setIsScreenViewLoaded] = useState<boolean>(false);
  const citiesLabel = [['villes', 'utilisateurs']];
  const [citiesData, setCitiesData] = useState<any>([]);
  const [isCitiesLoaded, setIsCitiesLoaded] = useState<boolean>(false);
  const chartDatas: ChartsData[] = [
    {
      labels: screenViewLabel,
      path: '/Statistiques/ecrans',
      values: screenViewData,
      setter: setScreenViewData,
      loader: isScreenViewLoaded,
      setLoader: setIsScreenViewLoaded,
      translate: TranslateScreen,
    },
    {
      labels: citiesLabel,
      path: '/Statistiques/villes',
      values: citiesData,
      setter: setCitiesData,
      loader: isCitiesLoaded,
      setLoader: setIsCitiesLoaded,
      translate: (s: string) => s,
    },
  ];
  // #endregion

  // #region data shown with number
  const [userNumber, setUserNumber] = useState<number | null>(null);
  const [appOpenNumber, setAppOpenNumber] = useState<number | null>(null);
  const [uninstallNumber, setUninstallNumber] = useState<number | null>(null);
  const [visitorNumber, setVisitorNumber] = useState<number | null>(null);
  const [downloadNumber, setDownloadNumber] = useState<number | null>(null);

  const getUninstallPercentage = (): string => {
    if (dataNumbers[0].value == null || dataNumbers[2].value == null) {
      return '';
    }
    const n = Math.round((dataNumbers[2].value / dataNumbers[0].value) * 100);
    return ` (${n.toString()}%)`;
  };

  const dataNumbers: DataNumber[] = [
    {
      label: "Nombre d'utilisateurs",
      details: "Nombre d'utilisateurs ayant ouvert l'application sur la période choisie.",
      path: '/Statistiques/nombre-utilisateurs',
      value: userNumber,
      setter: setUserNumber,
    },
    {
      label: "Nombre d'ouvertures d'applications",
      details: "Nombre de fois que l'application a été ouverte sur la période choisie.",
      path: '/Statistiques/nombre-ouverture-application',
      value: appOpenNumber,
      setter: setAppOpenNumber,
    },
    {
      label: 'Nombre de désinstallations',
      details:
        'Nombre de désinstalations réalisées par des appareil android sur la période choisie.',
      subLabel: '(Seules les désinstallations android sont comptabilisées.)',
      path: '/Statistiques/nombre-desinstallation',
      value: uninstallNumber,
      valueComplement: getUninstallPercentage,
      setter: setUninstallNumber,
    },
    {
      label: "Personnes ayant consulté la page de l'application",
      details: "Nombre de personne ayant consulté la page de l'application sur le Play Store.",
      path: '/Statistiques/playstore/nombre-consultation',
      value: visitorNumber,
      setter: setVisitorNumber,
    },
    {
      label: "Personnes ayant installé l'application",
      details: "Nombre de personne ayant installé l'application sur le Play Store",
      path: '/Statistiques/playstore/nombre-telechargement',
      value: downloadNumber,
      setter: setDownloadNumber,
    },
  ];
  // #endregion

  // #region get app data
  const [appVersion, setappVersion] = useState<string>('');
  const [apiVersion, setapiVersion] = useState<string>('');
  const [databaseVersion, setdatabaseVersion] = useState<string>('');
  const [adminWebsiteVersion, setadminWebsiteVersion] = useState<string>('');
  const [datecompil, setdatecompil] = useState<string>('');

  useEffect(() => {
    ApiFetch('/Statistiques/status', 'GET', history, '').then((response) => {
      if (response.ok) {
        response.json().then((resp) => {
          setappVersion(resp.appVersion);
          setapiVersion(resp.apiVersion);
          setdatabaseVersion(resp.databaseVersion);
          setadminWebsiteVersion(resp.adminWebsiteVersion);
          setdatecompil(resp.date);
        });
      }
    });
  });
  // #region statistics period
  // first day with available statistics
  const firstDay: Date = new Date(2021, 8, 1, 0, 0); // (month is 0-indexed)
  // last day with available statistics
  const lastDay: Date = getYesterdayString();
  // period shown on the screen
  const [begin, setBegin] = useState(new Date(firstDay));
  const [end, setEnd] = useState(new Date(lastDay));

  const getHandleDateChange =
    (setter: React.Dispatch<React.SetStateAction<Date>>): ((_date: Date | null) => void) =>
    (date: Date | null) => {
      if (date !== null) {
        date?.setHours(0);
        date?.setMinutes(0);
        date?.setSeconds(0);
        date?.setMilliseconds(0);
        setter(date);
      }
    };
  // #endregion

  // #region get charts data
  useEffect(() => {
    let isSubscribe = true;
    const body = {
      begin: begin.toISOString(),
      end: end.toISOString(),
    };

    chartDatas.forEach((chartData) => {
      ApiFetch(chartData.path, 'POST', history, body).then((response) => {
        if (response.ok) {
          response.json().then((resp) => {
            if (isSubscribe) {
              chartData.setter(
                resp
                  .map((row: [string, string]) => [chartData.translate(row[0]), Number(row[1])])
                  .sort((a: [string, number], b: [string, number]) => b[1] - a[1]),
              );
              chartData.setLoader(true);
            }
          });
        } else {
          onError("L'un des graphiques ne s'est pas affiché correctement");
        }
      });
    });
    return () => {
      isSubscribe = false;
    };
  }, [begin, end]);
  // #endregion

  // #region get number data
  useEffect(() => {
    let isSubscribe = true;
    const body = {
      begin: begin.toISOString(),
      end: end.toISOString(),
    };

    dataNumbers.forEach((data) => {
      ApiFetch(data.path, 'POST', history, body).then((response) => {
        if (response.ok) {
          response.json().then((resp) => {
            if (isSubscribe) {
              data.setter(Number(resp[0][0]));
            }
          });
        } else if (isSubscribe) {
          onError("L'une des données ne s'est pas affichée correctement.");
          data.setter(-1);
        }
      });
    });

    ApiFetch('/Statistiques/nombre-ouverte-application', 'POST', history, body).then((response) => {
      if (response.ok) {
        response.json().then((resp) => {
          if (isSubscribe) {
            setUserNumber(Number(resp[0][0]));
          }
        });
      }
    });
    return () => {
      isSubscribe = false;
    };
  }, [begin, end]);
  // #endregion

  const ccloClasses = UseBeecomingStyles();

  const ChartLoader: FunctionComponent<{}> = () => (
    <div style={{ height: 300 }}>
      <CircularProgress className={classes.loader} />
    </div>
  );

  return (
    <div>
      <h1>Statistiques</h1>
      <Button
        className={ccloClasses.BeecomingButton}
        style={{ margin: 10 }}
        variant="outlined"
        color="primary"
        href="https://console.firebase.google.com/project/cclo-57932/analytics"
      >
        Firebase
      </Button>
      <Button
        className={ccloClasses.BeecomingButton}
        style={{ margin: 10 }}
        variant="outlined"
        color="primary"
        href="https://appstoreconnect.apple.com/analytics/apps/d30"
      >
        Apple Store
      </Button>
      <Button
        className={ccloClasses.BeecomingButton}
        style={{ margin: 10 }}
        variant="outlined"
        color="primary"
        href="https://play.google.com/console/developers/?pli=1"
      >
        Google Play Store
      </Button>
      <div>
        <p>Période des statistiques</p>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
          <KeyboardDatePicker
            required
            label="à partir du"
            value={begin}
            format="dd/MM/yyyy"
            okLabel="Ok"
            cancelLabel="Annuler"
            invalidDateMessage="Date invalide"
            minDate={firstDay}
            maxDate={end}
            onChange={getHandleDateChange(setBegin)}
          />
          <KeyboardDatePicker
            required
            label="jusqu'au"
            value={end}
            format="dd/MM/yyyy"
            okLabel="Ok"
            cancelLabel="Annuler"
            invalidDateMessage="Date invalide"
            minDate={begin}
            maxDate={lastDay}
            onChange={getHandleDateChange(setEnd)}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="newElementContainer">
        <div className="newElementColumn">
          {isScreenViewLoaded ? (
            <ChartDataDisplay title="Utilisation de chaque écran" data={chartDatas[0]} />
          ) : (
            <ChartLoader />
          )}
        </div>
        <div className="newElementColumn">
          {isCitiesLoaded ? (
            <ChartDataDisplay title="villes d'utilisations" data={chartDatas[1]} />
          ) : (
            <ChartLoader />
          )}
        </div>
      </div>
      <div className="CardContainer">
        <DataNumberDisplay data={dataNumbers[0]} />
        <DataNumberDisplay data={dataNumbers[1]} />
        <DataNumberDisplay data={dataNumbers[2]} />
      </div>
      <div>
        <h3>Informations relatives au Play Store</h3>
      </div>
      <div className="CardContainer">
        <DataNumberDisplay data={dataNumbers[3]} />
        <DataNumberDisplay data={dataNumbers[4]} />
      </div>
      <div className="CCLO Status">
        <h3>
          Application version : {appVersion}, Api version :{apiVersion}, base de données version :
          {databaseVersion}, site version : {adminWebsiteVersion}. Compilé le {datecompil}
        </h3>
      </div>
    </div>
  );
};

const AdminStatisticsProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Statistiques"
    menuPath="statistics"
    screenName="statistics"
    adminPage={AdminStatistics}
  />
);

export default AdminStatisticsProtected;
