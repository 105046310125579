import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Button, CssBaseline, Drawer, Popover, Snackbar, Grid } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles, styled, Theme, ThemeProvider } from '@material-ui/core/styles';

import theme from '../../../../Themes/ProjectTheme';
import '../../Admin.scss';
import './AdminContainer.scss';
import AdminNavigation from '../Navigation/AdminNavigation';
import useCcloStyles from '../../../../Themes/UseBeecomingStyles';
import LogoutButton from '../LogoutButton';
import BeecomingDatas from '../../../../Datas/BeecomingDatas/BeecomingDatas';
import AdminTitle from '../../../../Types/ProjectTypes/AdminTitle';
import AdminScreenPathsList from '../../../../Datas/BeecomingDatas/AdminScreenPathsList';
import AdminPageComponent from '../../../../Types/Interface/ComponentInterface/AdminPageComponent';
import UserData from '../../../../Datas/UserData';

interface Props {
  title: AdminTitle;
  path: string;
  adminPage: AdminPageComponent;
}

/**
 * Notes
 * {@link adminPage} is not passed as a children argument
 * because it needs to have {@link AdminPageComponent} type
 */

/**
 * Standard admin page providing:
 * - an application navigation bar
 * - kind of a back button
 * - a button leading to user info
 * @param title Top left corner button label (not the <h1></h1> title of the page)
 * @param path Top left corner button path
 * @param adminLogin Admin login write on top right corner button
 * @param adminPage children (AdminPageComponent type)
 * @returns An admin page with {@link adminPage} as a child
 */

const AdminContainer: FunctionComponent<Props> = ({ title, path, adminPage }) => {
  const history = useHistory();
  const adminLogin = UserData.getBasicUser().email;

  const { drawerWidth } = BeecomingDatas;
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const useStyles = makeStyles((themeContainer: Theme) => ({
    root: {
      display: 'flex',
    },
    admin_appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: isMobile ? 0 : drawerWidth,
    },
    admin_drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    admin_drawerPaper: {
      width: drawerWidth,
    },
    admin_toolbar: themeContainer.mixins.toolbar,
    admin_content: {
      flexGrow: isMobile ? 4 : 1,
      padding: isMobile ? 0 : theme.spacing(2),
      maxWidth: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
    },
  }));
  const classes = useStyles();
  const ccloCasses = useCcloStyles();
  const [anchorLoginMenu, setAnchorLoginMenu] = useState<HTMLButtonElement | null>(null);
  const isLoginMenuOpen = Boolean(anchorLoginMenu);

  // eslint-disable-next-line no-shadow
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorLoginMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorLoginMenu(null);
  };

  // #region Error SnackBar
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const logAdminError = (message: string): void => {
    setErrorMessage(message);
  };

  const closeAdminError = (): void => {
    setErrorMessage(null);
  };

  // #endregion

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const checkMobile = () => {
    if (isMobile === true) {
      return 'temporary';
    }
    return 'permanent';
  };

  const openButton = () => {
    if (isMobile === true) {
      return (
        <Grid container justify="flex-start" alignItems="flex-start">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{ top: 3, left: 3 }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      );
    }
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.admin_appBar} />
        {openButton()}
        <Drawer
          className={classes.admin_drawer}
          variant={checkMobile()}
          classes={{
            paper: classes.admin_drawerPaper,
          }}
          anchor="left"
          open={open}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerHeader>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              edge="start"
              onClick={handleDrawerClose}
              sx={{ mr: 2, display: { sm: '1' } }}
            >
              <MenuIcon />
            </IconButton>
          </DrawerHeader>
          <img
            src="https://api.cclo.beecoming.fr/ressources/icons/logo_cclo.jpg"
            alt=""
            className="cclo_logo"
          />
          <AdminNavigation />
        </Drawer>
        <main className={classes.admin_content}>
          <div className="adminToolbarContainer">
            <div className="adminToolbarElement adminMiniTitle">
              <Button
                onClick={() => {
                  history.push(path);
                }}
                color="secondary"
                className={ccloCasses.BeecomingButton}
              >
                <b>{title}</b>
              </Button>
            </div>
            <div className="adminToolbarElement adminMiniLogin">
              <Button className={ccloCasses.BeecomingButton} onClick={handleClick}>
                <b>{adminLogin}</b>
              </Button>
              <Popover
                open={isLoginMenuOpen}
                anchorEl={anchorLoginMenu}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="adminLoginMenu">
                  <div>
                    <b>{adminLogin}</b>
                  </div>
                  <div>
                    <Button
                      className={ccloCasses.BeecomingButton}
                      color="primary"
                      onClick={() => {
                        history.push(AdminScreenPathsList.account);
                      }}
                    >
                      Mon compte
                    </Button>
                    <LogoutButton />
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="adminContainer">{adminPage({ onError: logAdminError })}</div>
        </main>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={errorMessage !== null}
          autoHideDuration={2000}
          onClose={closeAdminError}
          message={errorMessage}
        />
      </div>
    </ThemeProvider>
  );
};

export default AdminContainer;
