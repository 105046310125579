type BeecomingScreen =
  | 'Home screen'
  | 'Login screen'
  | 'Signup screen'
  | 'Agenda Screen'
  | 'Actuality Screen'
  | 'AroundMe Screen'
  | 'AroundMe List Screen'
  | 'Record Detail Screen'
  | 'WasteManagement Screen'
  | 'WasteManagementDetail Screen'
  | 'About Screen'
  | 'Collect Calendar Screen'
  | 'Parameters Screen'
  | 'NotificationParameters Screen'
  | 'Security Parameters Screen'
  | 'Forgot Password Screen'
  | 'Update Profile Parameters Screen';

type ScreenTranslate = {
  [_screen in BeecomingScreen]: string;
};

const screens: ScreenTranslate = {
  'Home screen': 'Accueil',
  'Login screen': 'Connexion',
  'Signup screen': 'Inscription',
  'Agenda Screen': 'Agenda',
  'Actuality Screen': 'Actualité',
  'AroundMe Screen': 'Autour de moi',
  'AroundMe List Screen': "Liste 'Autour de moi'",
  'Record Detail Screen': 'Détail du record',
  'WasteManagement Screen': 'Gestion des déchets',
  'WasteManagementDetail Screen': 'Détail de la gestion des déchets',
  'About Screen': 'A propos',
  'Collect Calendar Screen': 'Calendrier des collectes',
  'Parameters Screen': 'Paramètres',
  'NotificationParameters Screen': 'Paramètres de notification',
  'Security Parameters Screen': 'Paramètres de sécurité',
  'Forgot Password Screen': 'Mot de passe oublié',
  'Update Profile Parameters Screen': 'Modification des paramètres utilisateur',
};

function TranslateScreen(screen: string): string {
  const res: string = screens[screen as BeecomingScreen];
  if (res !== undefined) {
    return res;
  }
  return screen;
}

export default TranslateScreen;
