import React, { FunctionComponent } from 'react';
import { Divider, List, makeStyles } from '@material-ui/core';

import '../../Admin.scss';
import NavigationItemComponent from './NavigationItemComponent';
import NavigationList from '../../../../Datas/ProjectDatas/NavigationList';

interface Props {}

const AdminNavigation: FunctionComponent<Props> = () => {
  const useStyles = makeStyles(() => ({
    wasteDiv: {
      height: '100%',
    },
  }));

  const classes = useStyles();

  return (
    <List component="nav">
      {NavigationList.map((navigationItem) => (
        <div key={navigationItem.label}>
          <Divider />
          <NavigationItemComponent
            label={navigationItem.label}
            imgSrc={navigationItem.imgSrc}
            node={navigationItem.node}
          />
        </div>
      ))}
      <Divider />
      <div className={classes.wasteDiv} />
    </List>
  );
};

export default AdminNavigation;
