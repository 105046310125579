import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../Themes/UseBeecomingStyles';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';
import AdminProtection from '../BeecomingCompenents/AdminContainers/AdminProtection';
import AdminScreenPathsList from '../../../Datas/BeecomingDatas/AdminScreenPathsList';
import AdminPageComponent from '../../../Types/Interface/ComponentInterface/AdminPageComponent';

interface Props {}

const localPadding = '8pt';

const AdminPromote: AdminPageComponent = ({ onError }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const ccloClasses = UseBeecomingStyles();

  const handleValidate = () => {
    if (email === '') {
      setError(true);
    } else {
      openConfirmationDialog();
    }
  };

  const handleConfirm = () => {
    const requestData = {
      email,
      adminPassword: password,
    };
    ApiFetch('/utilisateur/admin/promote', 'POST', history, requestData).then((response) => {
      if (response.ok) {
        handleClose();
        history.push(AdminScreenPathsList.account);
      } else if (response.status === 404) {
        setError(true);
        onError("L'utilisateur n'a pas été trouvé.");
        handleClose();
      } else {
        setPasswordError(true);
        onError("L'utilisateur n'a pas été promu");
      }
    });
  };

  // #region Confirmation dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const openConfirmationDialog = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  // #endregion

  return (
    <div>
      <h1>Promouvoir un utilisateur</h1>
      <div style={{ padding: localPadding }}>
        <TextField
          value={email}
          label="email"
          error={error}
          variant="outlined"
          size="small"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setError(false);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
              handleValidate();
            }
          }}
        />
      </div>
      <div style={{ padding: localPadding }}>
        <Button variant="contained" onClick={handleValidate} size="small">
          Valider
        </Button>
      </div>
      <div style={{ padding: localPadding }}>
        <Typography>
          <Link href="/compte/nouveau">Créer un compte.</Link>
        </Typography>
      </div>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogContent>
          <div style={{ padding: localPadding }}>
            <Typography>Veulliez entrer votre mot de passe pour confirmer</Typography>
          </div>
          <div style={{ padding: localPadding }}>
            <TextField
              value={password}
              label="Mot de passe"
              type="password"
              error={passwordError}
              variant="outlined"
              style={{ margin: 'auto' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
                setPasswordError(false);
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter') {
                  handleConfirm();
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={ccloClasses.BeecomingButton} onClick={handleClose}>
            Annuler
          </Button>
          <Button className={ccloClasses.BeecomingButton} onClick={handleConfirm}>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AdminPromoteProtected: FunctionComponent<Props> = () => (
  <AdminProtection
    title="Promouvoir un utilisateur"
    screenName="admin-promote"
    menuPath="account"
    adminPage={AdminPromote}
  />
);

export default AdminPromoteProtected;
