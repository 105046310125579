import AdminScreen from '../../Types/BeecomingTypes/AdminScreen';

type AdminScreenPaths = {
  [_screen in AdminScreen]: string;
};

/**
 * This object have a key correspong to each admin screen.
 * For each admin screen gives the corresponding url suffix
 *
 * see {@link AdminScreen}
 */

const AdminScreenPathsList: AdminScreenPaths = {
  statistics: '/admin/statistiques',
  actuality: '/admin/administration/actualites',
  notification: '/admin/administration/notification',
  'notification-edition': '/admin/administration/notification/edition',
  'waste-collect': '/admin/administration/gestion-dechets',
  'waste-collect-edition': '/admin/administration/gestion-dechets/edition',
  'waste-collect-exception': '/admin/administration/gestion-dechets/exception',
  account: '/admin/mon-compte',
  'admin-promote': '/admin/promouvoir',
  home: '/admin',
};

export default AdminScreenPathsList;
