import User, { BasicUser, UserEditPath, UserOmited } from '../Types/BeecomingTypes/User';
import Address from '../Types/ProjectTypes/Address';

const editPaths: UserEditPath[] = [
  { key: 'firstName', path: '/utilisateur/mes-informations/prenom' },
  { key: 'lastName', path: '/utilisateur/mes-informations/nom-de-famille' },
  { key: 'email', path: '/compte/changement/email' },
  { key: 'phoneNumber', path: '/utilisateur/mes-informations/numero-telephone' },
  { key: 'address', path: '/utilisateur/mes-informations/adresse' },
];

// #region User
const userNull: User = {
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  address: null,
  token: null,
};

let user: User = userNull;

function removeUserData() {
  user = userNull;
}

function getUser() {
  return user;
}

function getBasicUser(): BasicUser {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    address: user.address,
    phoneNumber: user.phoneNumber,
  };
}

function setUser(newUser: User) {
  user = newUser;
}

function getUserProperty(k: keyof UserOmited): string | null {
  return user[k];
}

function setUserProperty(
  k: 'firstName' | 'lastName' | 'email' | 'phoneNumber',
  value: string | null,
): void {
  user[k] = value;
}

function setUserAddres(value: Address | null): void {
  user.address = value;
}

function isNull() {
  return user === userNull;
}
// #endregion

// #region User Properties
/**
 * give the set of edited properties, you can then send an API request for each element in this set
 * @param editedUser a user with edited properties
 * @returns an array containing properties which have been edited
 */
function getEditedProperties(editedUser: BasicUser): UserEditPath[] {
  const editedProperties: UserEditPath[] = [];
  editPaths.forEach((row) => {
    if (user[row.key] !== editedUser[row.key]) {
      editedProperties.push(row);
    }
  });
  return editedProperties;
}

function getUserCopy(src: BasicUser): BasicUser {
  return {
    firstName: src.firstName,
    lastName: src.lastName,
    email: src.email,
    phoneNumber: src.phoneNumber,
    address: src.address,
  };
}

function getAddressFromApiOutput(output: any): Address | null {
  if (output !== null) {
    return {
      commune: output.commune,
      streetName: output.streeetName,
      completeAdress: {
        numeroSelect: Number(output.completeAdress.numero_select),
        GeoPoint2d: output.completeAdress.geo_point_2d,
        codeInsee: Number(output.completeAdress.code_insee),
        collecteid:
          output.completeAdress.collecteid !== null
            ? (output.completeAdress.collecteid as string).split(',')
            : null,
      },
    };
  }
  return null;
}

function getUserFromApiOutput(output: any): User {
  return {
    firstName: output.firstName,
    lastName: output.lastName,
    email: output.email,
    phoneNumber: output.phoneNumber,
    address: getAddressFromApiOutput(output.address),
    token: output.token,
  };
}
// #endregion

export default {
  getUser,
  getBasicUser,
  setUser,
  getUserProperty,
  setUserProperty,
  setUserAddres,
  removeUserData,
  getEditedProperties,
  isNull,
};
export { getUserCopy, getUserFromApiOutput };
