/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import AllDays from '../../../../Datas/BeecomingDatas/AllDays';
import { DayItem, Order } from '../../../../Types/BeecomingTypes/BeecomingTypes';
import GetComparatorFromKeyInterface from '../../../../Types/Interface/MethodInterface/GetComparatorFromKeyInterface';
import WasteManagementInterface from '../../../../Types/Interface/TableInterfaces/DataInterface/WasteManagementInterface';

const GetWasteComparatorFromKey: GetComparatorFromKeyInterface<WasteManagementInterface> = (
  key: keyof WasteManagementInterface,
  typeOrder: Order,
) => {
  const sign: number = typeOrder === 'asc' ? 1 : -1;
  switch (key) {
    case 'zoneidlabel':
      return (a: WasteManagementInterface, b: WasteManagementInterface) =>
        sign * a.zoneidlabel.localeCompare(b.zoneidlabel);

    case 'typeid':
      return (a: WasteManagementInterface, b: WasteManagementInterface) =>
        sign * (a.typeid - b.typeid);
    case 'jour':
      return (a: WasteManagementInterface, b: WasteManagementInterface) => {
        const dayA: DayItem = AllDays.find((value) => value.name === a.jour) ?? AllDays[0];
        const dayB: DayItem = AllDays.find((value) => value.name === b.jour) ?? AllDays[0];
        return sign * (dayA.id - dayB.id);
      };
    default:
      return (a: WasteManagementInterface, b: WasteManagementInterface) =>
        sign * a[key].toLocaleString().localeCompare(b[key].toLocaleString());
  }
};

export default GetWasteComparatorFromKey;
