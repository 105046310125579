/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  NotificationTopic,
  TopicCategory,
} from '../../../Types/Interface/TableInterfaces/DataInterface/NotificationInterface';
import ApiFetch from '../../../Methods/RefreshToken/ApiRequest';

interface Props {
  topics: NotificationTopic;
  setTopics: React.Dispatch<React.SetStateAction<NotificationTopic>>;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

// #region Types
// take the new array values and the key of the modified child
// edit the values and return the final values
type SelectionSetter = (_values: string[], _newValue: string) => string[];

type TopicKey = 'subCategories' | 'zones';

interface Selector {
  values: string[];
  valuesSetter: SelectionSetter;
  allValues: [string, string][];
  selectAll?: [string, string][];
}

type FullSelector = {
  [_topicKey in TopicKey]: Selector;
};

// SelectorLabels regroup properties shared by a TopicKey
// independently from TopicCategory
interface SelectorLabel {
  label: string;
  error: boolean;
}

// #region FullSelectors
// - FullSelectors
// - - - - FullSelector:   Event                  # TopicCategory
// - - - - - - - Selector:    Cities                 # TopicKey
// - - - - - - - Selector:    SubCategories          # TopicKey
// - - - - FullSelector:   Waste                  # TopicCategory
// - - - - - - - Selector:    Zones                  # TopicKey
// - - - - - - - Selector:    Waste Collect type     # TopicKey
type FullSelectors = {
  [_category in TopicCategory]: FullSelector;
};

type SelectorLabels = {
  [_topicKey in TopicKey]: SelectorLabel;
};
// #endregion

interface DynamicData {
  path: string;
  setter: React.Dispatch<React.SetStateAction<[string, string][]>>;
}
// #endregion

const TopicSelector: FunctionComponent<Props> = ({ topics, setTopics, error, setError }) => {
  const history = useHistory();

  // selectable
  const categories: TopicCategory[] = ['Evénements', 'Gestion des déchets'];
  const [agendaCities, setAgendaCities] = useState<[string, string][]>([]);
  const selectAllCities: [string, string] = ['-1', 'Toutes les villes'];
  const [agendaCategories, setAgendaCategories] = useState<[string, string][]>([]);
  const [dumpsterZones, setDumpsterZones] = useState<[string, string][]>([]);
  const dumpsterCategories: [string, string][] = [
    ['yellowDumpster', 'bac jaune'],
    ['greenDumpster', 'bac vert'],
  ];
  const dynamicSelectable: DynamicData[] = [
    { path: '/OpenData/communes-code-insee', setter: setAgendaCities },
    { path: '/OpenData/record/agenda-rss/Categories', setter: setAgendaCategories },
    { path: '/OpenData/collecte-collecteid', setter: setDumpsterZones },
  ];

  // selected
  const [category, setCategory] = useState<TopicCategory>(topics.category);
  const [selectedAgendaCities, setSelectedAgendaCities] = useState<string[]>([]);
  const [selectedAgendaCategories, setSelectedAgendaCategories] = useState<string[]>([]);
  const [selectedDumpsterZones, setSelectedDumpsterZones] = useState<string[]>([]);
  const [selectedDumpsterCategories, setSelectedDumpsterCategories] = useState<string[]>([]);

  // errors
  const [subCategoryError, setSubCategoryError] = useState(false);
  const [zoneError, setZoneError] = useState(false);

  const selectors: FullSelectors = {
    Evénements: {
      zones: {
        values: topics.zones.length ? topics.zones : selectedAgendaCities,
        valuesSetter: (_selectAll: any, newValue: string) => {
          setSelectedAgendaCities([newValue]);
          setZoneError(false);
          return [newValue];
        },
        allValues: agendaCities,
        selectAll: [selectAllCities],
      },
      subCategories: {
        values: topics.subCategories.length ? topics.subCategories : selectedAgendaCategories,
        valuesSetter: (values: string[]) => {
          let newValues = values;
          if (values.length > 4) {
            newValues = values.slice(1);
          }
          setSelectedAgendaCategories(newValues);
          setSubCategoryError(false);
          return values;
        },
        allValues: agendaCategories,
      },
    },
    'Gestion des déchets': {
      zones: {
        values: topics.zones.length ? topics.zones : selectedDumpsterZones,
        valuesSetter: (values: string[], newValue: string) => {
          let newValues;
          if (newValue === selectAllCities[0]) {
            newValues = [selectAllCities[0]];
          } else if (values[0] === selectAllCities[0]) {
            newValues = values.slice(1);
          } else {
            newValues = values;
          }
          setSelectedDumpsterZones(newValues);
          setZoneError(false);
          return newValues;
        },
        allValues: dumpsterZones,
      },
      subCategories: {
        values: topics.subCategories.length ? topics.subCategories : selectedDumpsterCategories,
        valuesSetter: (values: string[]) => {
          setSelectedDumpsterCategories(values);
          setSubCategoryError(false);
          return values;
        },
        allValues: dumpsterCategories,
      },
    },
  };

  const selectorLabels: SelectorLabels = {
    zones: {
      label: 'Communes',
      error: zoneError,
    },
    subCategories: {
      label: 'Sous catégories',
      error: subCategoryError,
    },
  };

  useEffect(() => {
    let isSubsribe = true;

    dynamicSelectable.forEach((selectable) => {
      ApiFetch(selectable.path, 'GET', history).then((response) => {
        if (response.ok) {
          response.json().then((resp) => {
            if (isSubsribe) {
              selectable.setter(
                (resp as [string, string][]).sort((a: [string, string], b: [string, string]) =>
                  a[1].localeCompare(b[1]),
                ),
              );
            }
          });
        }
      });
    });
    return () => {
      isSubsribe = false;
    };
  }, []);

  useEffect(() => {
    if (error) {
      if (topics.subCategories.length === 0) {
        setSubCategoryError(true);
      }
      if (topics.zones.length === 0) {
        setZoneError(true);
      }
    }
  }, [error]);

  const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newCategory: TopicCategory = event.target.value as TopicCategory;
    const newTopic: NotificationTopic = {
      category: newCategory,
      subCategories: topics.subCategories,
      zones: topics.zones,
    };
    setCategory(event.target.value as TopicCategory);
    setTopics(newTopic);
    setError(false);
  };

  const getHandleChange =
    (setter: SelectionSetter, topicKey: TopicKey) =>
    (event: React.ChangeEvent<{ value: unknown }>, child: any) => {
      const key: string = (child.key as string).substring(2);
      const newValues: string[] = event.target.value as string[];
      const newTopic: NotificationTopic = {
        category: topics.category,
        subCategories: topics.subCategories,
        zones: topics.zones,
      };
      const finalValues = setter(newValues, key);
      newTopic[topicKey] = finalValues;
      setTopics(newTopic);
      setError(false);
    };

  const getMenu = (topicKey: TopicKey, selector: Selector, selectorLabel: SelectorLabel) => {
    const init = selector.selectAll ?? [];
    return (
      <FormControl style={{ width: '150pt', maxWidth: '150pt' }}>
        <InputLabel>{selectorLabel.label}</InputLabel>
        <Select
          multiple
          onChange={getHandleChange(selector.valuesSetter, topicKey)}
          value={selector.values}
          error={selectorLabel.error}
        >
          {init.concat(selector.allValues).map((item: [string, string]) => (
            <MenuItem key={item[0]} value={item[0]}>
              {item[1]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getSelector = (selector: FullSelector): ReactElement => (
    <div style={{ display: 'inline' }}>
      {getMenu('subCategories', selector.subCategories, selectorLabels.subCategories)}
      {getMenu('zones', selector.zones, selectorLabels.zones)}
    </div>
  );

  return (
    <div>
      <FormControl style={{ maxWidth: '100pt' }}>
        <InputLabel>Catégorie</InputLabel>
        <Select
          onChange={handleChangeCategory}
          defaultValue={category}
          value={topics.category ? topics.category : category}
          error={error}
        >
          {categories.map((item: TopicCategory) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {getSelector(selectors[topics.category.length ? topics.category : category])}
    </div>
  );
};

export default TopicSelector;
