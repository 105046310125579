/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { HeadCell, Order } from '../../../../../Types/BeecomingTypes/BeecomingTypes';
import DataInterface from '../../../../../Types/Interface/TableInterfaces/DataInterface/DataInterface';

interface Props<RowType extends DataInterface> {
  headCells: HeadCell<RowType>[];
  orderType: Order;
  onChangeOrderType: (_t: Order) => void;
  orderBy: keyof RowType;
  onChangeOrderBy: (_k: keyof RowType) => void;
}

const ListHeaderOrder = <RowType extends DataInterface>({
  headCells,
  orderType,
  onChangeOrderType,
  orderBy,
  onChangeOrderBy,
}: Props<RowType>): ReactElement => (
  <TableRow>
    {headCells.map((headCell: HeadCell<RowType>) =>
      headCell.name !== '' ? (
        <TableCell
          align={headCell.align}
          key={headCell.id}
          sortDirection={orderBy === headCell.keyValue ? orderType : undefined}
          style={{ fontWeight: 'normal' }}
        >
          <TableSortLabel
            active={orderBy === headCell.keyValue}
            direction={orderBy === headCell.keyValue ? orderType : undefined}
            onClick={(_event: React.MouseEvent<unknown>) => {
              if (orderBy === headCell.keyValue) {
                onChangeOrderType(orderType === 'asc' ? 'desc' : 'asc');
              } else {
                onChangeOrderBy(headCell.keyValue);
              }
            }}
          >
            {headCell.name}
          </TableSortLabel>
        </TableCell>
      ) : (
        <TableCell key={headCell.id} />
      ),
    )}
  </TableRow>
  // eslint-disable-next-line indent
);

export default ListHeaderOrder;
