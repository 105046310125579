import { Button, TextField, ThemeProvider } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProjectTheme from '../../../Themes/ProjectTheme';
import UseBeecomingStyles from '../../../Themes/UseBeecomingStyles';
import { ApiFetchUnLog } from '../../../Methods/RefreshToken/ApiRequest';
import './CreateAccount.scss';
import OtherScreenPathsList from '../../../Datas/BeecomingDatas/OtherScreenPathsList';

interface Props {}

const CreateAccount: FunctionComponent<Props> = () => {
  const history = useHistory();
  // fields
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // errors
  const [globalError, setGlobalError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  // #region Validate
  const handleValidate = () => {
    let isValid = true;

    // #region check if every fields are valid
    if (firstname === '') {
      isValid = false;
      setFirstnameError(true);
    }
    if (lastname === '') {
      isValid = false;
      setLastnameError(true);
    }
    if (email === '') {
      isValid = false;
      setEmailError(true);
    }
    if (password === '' || password !== confirmPassword) {
      isValid = false;
      setPasswordError(true);
    }
    // #endregion
    const requestData = {
      firstName: firstname,
      lastName: lastname,
      email,
      password,
      passwordConfirmation: confirmPassword,
      language: 'fr',
    };

    if (isValid) {
      ApiFetchUnLog('/compte/inscription', 'PUT', requestData).then((response) => {
        if (response.ok) {
          history.push(OtherScreenPathsList.home);
        } else {
          setGlobalError(true);
        }
      });
    }
  };
  // #endregion

  // themes
  const ccloClasses = UseBeecomingStyles();
  return (
    <ThemeProvider theme={ProjectTheme}>
      <div>
        <h1>Inscription</h1>
        <div className="RegisterContainer">
          <div className="registerProperty">
            <TextField
              value={firstname}
              label="Prénom"
              error={firstnameError || globalError}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFirstname(event.target.value);
                setFirstnameError(false);
                setGlobalError(false);
              }}
            />
          </div>
          <div className="registerProperty">
            <TextField
              value={lastname}
              label="Nom"
              error={lastnameError || globalError}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLastname(event.target.value);
                setLastnameError(false);
                setGlobalError(false);
              }}
            />
          </div>
          <div className="registerProperty">
            <TextField
              value={email}
              label="Email"
              type="email"
              error={emailError || globalError}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
                setEmailError(false);
                setGlobalError(false);
              }}
            />
          </div>
          <div className="registerProperty">
            <TextField
              value={confirmPassword}
              label="Mot de passe"
              type="password"
              error={passwordError || globalError}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value);
                setPasswordError(false);
                setGlobalError(false);
              }}
            />
          </div>
          <div className="registerProperty">
            <TextField
              value={password}
              label="Confirmation"
              type="password"
              error={passwordError || globalError}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
                setPasswordError(false);
                setGlobalError(false);
              }}
            />
          </div>
          <div className="registerProperty">
            <Button
              className={ccloClasses.BeecomingButton}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleValidate}
            >
              Valider
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default CreateAccount;
