import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UseBeecomingStyles from '../../../Themes/UseBeecomingStyles';
import { ApiFetchUnLog } from '../../../Methods/RefreshToken/ApiRequest';

interface Props {}

const ForgotPasswod: FunctionComponent<Props> = () => {
  // #region States
  const history = useHistory();
  const [email, setEmail] = useState<string>('');

  // dialogs
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSnackBar, setIsSnackBar] = useState<boolean>(false);

  // styles
  const ccloClasses = UseBeecomingStyles();
  // #endregion

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // #region Functions
  const handleValidate = () => {
    const requestBody = {
      email,
    };
    ApiFetchUnLog('/compte/mot-de-passe/recuperation', 'POST', requestBody).then((response) => {
      if (response.ok) {
        setIsDialogOpen(true);
      } else {
        setIsSnackBar(true);
      }
    });
  };

  // #region Dailogs
  const closeDialog = () => {
    setIsDialogOpen(false);
    history.push('connexion');
  };

  const closeSnackBar = () => {
    setIsSnackBar(false);
  };
  // #endregion

  const ccloStyles = UseBeecomingStyles();
  // #endregion
  return (
    <div>
      <h1>Veuilliez saisir votre adresse email</h1>
      <p>Un lien de changement de mot de passe sera envoyé à cette adresse.</p>
      <div className="InputElement">
        <TextField
          type="email"
          onChange={changeEmail}
          value={email}
          variant="outlined"
          size="small"
          label="email"
        />
      </div>
      <div className="InputElement">
        <Button
          className={ccloStyles.BeecomingButton}
          variant="contained"
          color="primary"
          onClick={handleValidate}
        >
          Envoyer
        </Button>
      </div>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          <Typography>Un mail de confirmation a été envoyé à votre adresse email.</Typography>
        </DialogContent>
        <DialogActions>
          <Button className={ccloClasses.BeecomingButton} onClick={closeDialog}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isSnackBar}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        message="Une erreur s'est produite"
      />
    </div>
  );
};

export default ForgotPasswod;
