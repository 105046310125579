/* eslint-disable consistent-return */
/* eslint-disable implicit-arrow-linebreak */
import SetDataSharedInterface from '../../../Types/Interface/MethodInterface/GetDataSharedInterface';
import ActualitySharedData from '../../../Types/Interface/TableInterfaces/SharedDataInterface/ActualitySharedData';
import ApiFetch from '../../RefreshToken/ApiRequest';

const GetActualityHighLight: SetDataSharedInterface<ActualitySharedData> = ({ history }) =>
  ApiFetch('/OpenData/nouvelles?rows=1&displayLevel=3&thisDisplayLevelOnly=true', 'GET', history)
    .then((response) => response.json())
    .then((resp) => {
      if (!!resp.length && resp.length > 0) {
        return { highlight: resp[0].recordid };
      }

      return ApiFetch(
        '/OpenData/nouvelles?rows=1&displayLevel=2&thisDisplayLevelOnly=true',
        'GET',
        history,
      )
        .then((response) => response.json())
        .then((resp2) => {
          if (resp2.length !== 0) {
            return { highlight: resp2[0].recordid };
          }
          return { highlight: '' };
        });
    });

export default GetActualityHighLight;
